import { DeleteDemand, GetDemands } from '../api/demand'
import { UpdateParagraph } from '../api/paragraph'
import { Api, Demand, DemandStatus, Law, Origin, Paragraph } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { UseFormReset } from 'react-hook-form'
import { NavigateFunction } from 'react-router-dom'
import React from 'react'
import { setLoading, UpdateItemInCollection } from './stateHelper'
import { t } from '../i18n/i18n'

const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })

export enum SelectedDemandModal {
   ARCHIVE,
   DELETE,
   UNDO,
   PARENT,
}

export const deleteUnselectedParagraphDemands = async (
   paragraphs: Paragraph[],
   SelectedParagraphs: (Demand & {
      paragraphId: number
   })[],
   law: Law
): Promise<Paragraph[]> => {
   try {
      if (
         JSON.stringify(
            paragraphs
               .filter((x) => x.demandId)
               .map((x) => x.demandId)
               .sort()
         ) !==
         JSON.stringify(
            SelectedParagraphs.filter((x) => x.id)
               .map((x) => x.id)
               .sort()
         )
      ) {
         const paragraphsToDelete = paragraphs.filter((paragraph) => {
            return paragraph.demandId && !SelectedParagraphs.some((selectedParagraph) => selectedParagraph.paragraphId === paragraph.id)
         })
         await Promise.all(
            paragraphsToDelete.map(async (paragraph) => {
               await DeleteDemand(paragraph.demandId)
               paragraphs.find((x) => x.id === paragraph.id).demandId = null
               paragraphs.find((x) => x.id === paragraph.id).law = law
               UpdateParagraph(paragraphs.find((x) => x.id === paragraph.id))
            })
         )
      }
      return paragraphs
   } catch (error) {}
}

export const deleteDemands = async (demandIds: string[]): Promise<void> => {
   await Promise.all(
      demandIds.map(async (id) => {
         await DeleteDemand(id)
      })
   )
}
export const statusBadgeColor = (lvl: number): 'brand' | 'success' | 'warning' | 'danger' => {
   switch (lvl) {
      case 0:
         return 'success'
      case 1:
         return 'warning'
      default:
         break
   }
}
export const getNewDemand = (parentDemand: Demand, modifiedBy: string): Demand => ({
   area: parentDemand?.area,
   level: parentDemand?.level + 1,
   modifiedBy: modifiedBy,
   origin: Origin.Sweden,
   parentId: parentDemand?.id,
   source: '',
   type: parentDemand?.type,
   subType: null,
   text: '',
   url: '',
   urlText: '',
   designation: '',
})

export const updateDemand = async (
   demandToUpdate: Demand,
   dataContext: IDataContext,
   reset: UseFormReset<Demand>,
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   setOriginDemand: React.Dispatch<React.SetStateAction<Demand>>,
   setBread: (parentId: string) => Promise<void>
) => {
   try {
      const updatedDemand: Demand = (await apiInstance.api.demandUpdate(demandToUpdate)).data

      const demands: Demand[] = (await apiInstance.api.demandList()).data

      dataContext.setRootState((prev) => ({ ...prev, demands }))
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')

      reset(updatedDemand)
      setOriginDemand(updatedDemand)
      await setBread(updatedDemand.parentId)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const hasActiveChildDemands = (demand: Demand): boolean => {
   if (!demand.children) {
      return false;
   }
   return demand.children.some((x) => x.status !== DemandStatus.Archived)
}
export const hasArchivedChildDemands = (demand: Demand): boolean => {
   if (!demand.children) {
      return false;
   }

   return demand.children.some((x) => x.status === DemandStatus.Archived)
}
export const hasQuestions = (demand: Demand): boolean => {
   return demand.questions && demand.questions.length > 0
}

const updateParentDemand = async (updatedDemands: Demand[], demandsToMove: Demand[], targetDemand: Demand) => {
   demandsToMove.forEach((demand) => {
      updatedDemands.push({
         ...demand,
         parentId: targetDemand.id,
      })
   })
}

export const onDemandMove = async (dataContext: IDataContext, targetDemand: Demand, onModalClose: () => void) => {
   const { selectedDemands } = dataContext.state
   const lvlTwoDemands = selectedDemands.filter((x) => x.level === 2)
   const lvlThreeDemands = selectedDemands.filter((x) => x.level === 3)
   let updatedDemands: Demand[] = []
   setLoading(dataContext.setRootState, true)
   try {
      await updateParentDemand(updatedDemands, targetDemand.level === 1 ? lvlTwoDemands : lvlThreeDemands, targetDemand)
      await apiInstance.api.demandBatchUpdate(updatedDemands)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('DemandMoveError'), error.message)
   } finally {
      const demands = await GetDemands()

      dataContext.setRootState((prev) => ({
         ...prev,
         isLoading: false,
         moveDemands: false,
         selectedDemands: [],
         demands: demands,
      }))
      onModalClose()
   }
}

export const toggleArchiveDemand = async (
   demand: Demand,
   dataContext: IDataContext,
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   modifiedBy: string,
   grandParentId: string,
   setOpenModal: React.Dispatch<React.SetStateAction<SelectedDemandModal>>
) => {
   setIsSubmitting(true)
   try {
      demand.modifiedBy = modifiedBy
      await apiInstance.api.demandArchiveUpdate(demand)

      const updatedDemands = (await apiInstance.api.demandList()).data
      dataContext.setRootState((prev) => ({...prev, demands: updatedDemands}))

      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')
      setOpenModal(null)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const deleteDemandAndResetLaw = async (
   demand: Demand,
   dataContext: IDataContext,
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   modifiedBy: string,
   grandParentId: string,
   navigate: NavigateFunction
) => {
   setIsSubmitting(true)
   try {
      const { demands } = dataContext.state
      const updatedDemands = (await apiInstance.api.demandResetlawDelete(demand.id, modifiedBy)).data
      const grandParent = demands.find((d) => d.id === grandParentId)
      
      if (demand.status !== DemandStatus.Archived) {
         if (demand.level === 2) {
            grandParent.children = updatedDemands.sort((a: any, b: any) => a.order - b.order)
         }
         if (demand.level === 3) {
            const parent = grandParent.children.find((y) => y.id === demand.parentId)
            parent.children = updatedDemands.sort((a: any, b: any) => a.order - b.order)
         }
      }

      UpdateItemInCollection(dataContext.setRootState, demands, grandParent, 'demands')

      dataContext.handleMessage(dataContext.setRootState, 'success', t('ItemDeleted'), '')

      navigate('/demands')
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('DeleteError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const addDemand = async (
   demand: Demand,
   grandParentId: string,
   dataContext: IDataContext,
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   navigate: NavigateFunction
) => {
   try {
      const { demands } = dataContext.state
      const newDemand: Demand = (await apiInstance.api.demandCreate(demand)).data
      let grandParent: Demand

      if (demand.level === 2) {
         grandParent = demands.find((x) => x.id === demand.parentId)

         grandParent.children.push(newDemand)
         grandParent.children = grandParent.children.sort((a: any, b: any) => a.order - b.order)
      }
      if (demand.level === 3) {
         grandParent = demands.find((x) => x.id === grandParentId)
         const parent = grandParent.children.find((y) => y.id === newDemand.parentId)

         parent.children.push(newDemand)
         parent.children = parent.children.sort((a: any, b: any) => a.order - b.order)
      }
      UpdateItemInCollection(dataContext.setRootState, demands, grandParent, 'demands')

      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')
      navigate(`/demands/${newDemand.id}`)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const addDemandFromList = async (demand: Demand, demandsToUpdate: Demand[], parent: Demand, dataContext: IDataContext, navigate: NavigateFunction) => {
   try {
      const newDemand: Demand = (await apiInstance.api.demandCreate(demand)).data
      await apiInstance.api.demandBatchUpdate(demandsToUpdate)

      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')

      navigate(`/demands/${newDemand.id}`)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      const demands = await GetDemands()
      dataContext.setRootState(prev => ({...prev, demands}))
   }
}

export const demandSelectIsDisabled = (selectedDemands: Demand[], demand: Demand) => {
   const lvlOneHasNoChildren = !hasActiveChildDemands(demand) && demand.level === 1
   const lvlThreeParentIsSelected = demand.level === 3 && selectedDemands.some((x) => x.id === demand.parentId)
   const lvlThreeWithoutParentIsDisabled = demand.level === 3 && selectedDemands.some((x) => x.level === 2)
   const lvlThreeValuesWithoutParent =
      demand.level === 2 && selectedDemands.filter((x) => x.level === 2).length < 1 && selectedDemands.some((x) => x.level === 3)
   if (lvlOneHasNoChildren) {
      return lvlOneHasNoChildren
   }
   if (lvlThreeParentIsSelected) {
      return lvlThreeParentIsSelected
   }
   if (lvlThreeWithoutParentIsDisabled) {
      return lvlThreeWithoutParentIsDisabled
   }
   if (lvlThreeValuesWithoutParent) {
      return lvlThreeValuesWithoutParent
   } else {
      return false
   }
}

export const onDemandSelect = async (
   selectedDemands: Demand[],
   selectedDemand: Demand,
   expandedDemands: string[],
   checked: boolean,
   dataContext: IDataContext,
   toggleRowExpansion: (demandId: string) => void
) => {
   let movableItems: Demand[] = selectedDemands

   const expandAndSelect = (demand: Demand) => {
      movableItems.push(selectedDemand)
      const exists = (demandId: string) => {
         return movableItems.some((x) => x.id === demandId)
      }
      demand.children.forEach((child) => {
         if (!exists(child.id)) {
            movableItems.push(child)
            if (child?.children?.length > 0) {
               child?.children.forEach((grandChild) => {
                  if (!exists(grandChild.id)) {
                     movableItems.push(grandChild)
                  }
               })
            }
         }
      })
   }

   if (checked) {
      expandAndSelect(selectedDemand)

      const hasChildren = (demand: Demand) => {
         return demand?.children?.length > 0
      }
      const isExpanded = (demandId: string) => {
         return expandedDemands.some((demand) => demand === demandId)
      }
      if (!isExpanded(selectedDemand.id)) {
         toggleRowExpansion(selectedDemand.id)
      }

      if (hasChildren(selectedDemand)) {
         // eslint-disable-next-line array-callback-return
         selectedDemand.children.map((child) => {
            if (!isExpanded(child.id)) {
               toggleRowExpansion(child.id)
            }
         })
      }
   } else {
      movableItems = movableItems.filter((x) => x.id !== selectedDemand.id)
      const lvlThreeWithoutParentId = movableItems.filter((x) => x.parentId === selectedDemand.id && x.level === 3)
      const selectedLvlTwos = movableItems.find((x) => x.level === 2)

      if (selectedLvlTwos && lvlThreeWithoutParentId) {
         lvlThreeWithoutParentId.forEach((lvlThree) => {
            movableItems = movableItems.filter((x) => x.id !== lvlThree.id)
         })
      }
   }
   dataContext.setRootState((prev) => ({
      ...prev,
      selectedDemands: movableItems,
   }))
}

export const onDemandMoveClick = (dataContext: IDataContext) => {
   dataContext.setRootState((prev) => ({
      ...prev,
      moveDemands: !prev.moveDemands,
      selectedDemands: dataContext.state.moveDemands ? [] : prev.selectedDemands,
   }))
}

export const levelBadgeColor = (lvl: number, classes: any) => {
   switch (lvl) {
      case 1:
         return classes.brand
      case 2:
         return classes.gold
      case 3:
         return classes.informative
      default:
         break
   }
}

export const isDemandFormDisabled = (selectedDemand: Demand) => {
   return selectedDemand && selectedDemand.status === DemandStatus.Archived
}
